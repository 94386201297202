import { getRequestDetails, postRequestDetails } from "config/requestHeaders";
import axios from "../../../config/axiosCall";

export const getInvoiceDetails = async (payload) => {
  const { id, refNum } = payload;

  let param = "";
  if (id) param = `submissionId=${id}`;
  if (refNum) param = `ReferenceNumber=${refNum}`;

  const url = `/Submission/submissionById?${param}`;
  const response = await axios(url, {
    ...getRequestDetails,
    data: payload,
  });

  const { data = null } = response;

  if (response.status === 200 || response.status === 202) {
    //converting keys to camelCase
    response.data.submissionDetails.billingAddress = JSON.stringify(
      Object.fromEntries(
        Object.entries(JSON.parse(response.data.submissionDetails.billingAddress)).map(([k, v]) => 
          [k.charAt(0).toLowerCase() + k.slice(1), v])
      )
    );
    response.data.submissionDetails.shippingAddress = JSON.stringify(
      Object.fromEntries(
        Object.entries(JSON.parse(response.data.submissionDetails.shippingAddress)).map(([k, v]) => 
          [k.charAt(0).toLowerCase() + k.slice(1), v])
      )
    );

    return data;
  }
  throw new Error("Failed to save data. Please try again");
};
export const draftInvoice = async (payload) => {
  const response = await axios("EInvoice/DraftSubmission", {
    ...postRequestDetails,
    data: payload,
  });

  const { data = null } = response;

  if (response.status === 200 || response.status === 202) {
    return data;
  }
  throw new Error("Failed to save data. Please try again");
};

export const submitInvoice = async (payload) => {
  const response = await axios("EInvoice/ApproveSubmission", {
    ...postRequestDetails,
    data: payload,
  });

  const { data = null } = response;

  if (response.status === 200 || response.status === 202) {
    return data;
  }
  throw new Error("Failed to save data. Please try again");
};

export const submitBulkInvoices = async (payload) => {
  const response = await axios("Einvoice/ApproveSubmissionBulk", {
    ...postRequestDetails,
    data: payload,
  });

  const { data = null } = response;

  if (response.status === 200 || response.status === 202) {
    return data;
  }
  throw new Error("Failed to save data. Please try again");
};

export const uploadInvoice = async (payload) => {
  const response = await axios("/Submission/InvoiceUpload", {
    ...postRequestDetails,
    data: payload,
  });

  const { data = null } = response;

  if (response.status === 200 || response.status === 202) {
    return data;
  }
  throw new Error("Failed to save data. Please try again");
};
